// TODO: this should not be necessary.  leaf-validation-message is added via validationcontroler and all form comonents
// use scopedregistry mixin to pull in leaf-validation-message but it does not work. Look into this after form controls
// have been re written.
import '@cigna/dcws_leaf-web-components/components/validation-message/validation-message.js';
import { Loader } from '@cigna/dcws_leaf-web-components/utilities/component-loader.js';
import type { RegisteredComponent } from '@cigna/dcws_leaf-web-components/utilities/component-loader.js';

const apps: Record<string, RegisteredComponent> = {
  'chcapp-medicare-plan-finder': {
    register() {
      return import('./apps/medicare-plan-finder/medicare-plan-finder.js');
    },
  },
};

const components: Record<string, RegisteredComponent> = {
  ...apps,
  'chc-value-props': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/value-props/value-props.js');
    },
  },
  'chc-value-prop-heading': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/value-prop-heading/value-prop-heading.js');
    },
  },
  'chc-value-prop': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/value-prop/value-prop.js');
    },
  },
  'chc-tag-list': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/tag-list/tag-list.js');
    },
  },
  'chc-table': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/table/table.js');
    },
  },
  'chc-sticky-banner': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/sticky-banner/sticky-banner.js');
    },
  },
  'chc-sticky-anchor-links': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/sticky-anchor-links/sticky-anchor-links.js');
    },
  },
  'chc-skiplink': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/skiplink/skiplink.js');
    },
  },
  'chc-search': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/search/search.js');
    },
  },
  'chc-scroll-section': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/scroll-section/scroll-section.js');
    },
  },
  'chc-row': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/row/row.js');
    },
  },
  'chc-quote': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/quote/quote.js');
    },
  },
  'chc-promo': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/promo/promo.js');
    },
  },
  'chc-product-card': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-group': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-header': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-footer': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-footer-compare': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-content': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-cta': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-drug-coverage': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-provider': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-provider-doctor': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-product-card-copay': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/product-card/product-card.js');
    },
  },
  'chc-phone-number': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/phone-number/phone-number.js');
    },
  },
  'chc-meta-tag-replacer': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/meta-tag-replacer/meta-tag-replacer.js');
    },
  },
  'chc-hero': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/hero/hero.js');
    },
  },
  'chc-heading-list-item': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/heading-list-item/heading-list-item.js');
    },
  },
  'chc-heading-list': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/heading-list/heading-list.js');
    },
  },
  'chc-header-search': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/header-search/header-search.js');
    },
  },
  'chc-header-language': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/header-language/header-language.js');
    },
  },
  'chc-header-dropdown': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/header-dropdown/header-dropdown.js');
    },
  },
  'chc-header': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/header/header.js');
    },
  },
  'chc-footer': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/footer/footer.js');
    },
  },
  'chc-countdown': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/countdown/countdown.js');
    },
  },
  'chc-back-to-top': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/back-to-top/back-to-top.js');
    },
  },
  'chc-article-group': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/article-group/article-group.js');
    },
  },
  'chc-article': {
    register() {
      return import('@cigna/dcws_leaf-web-components/chc-components/article/article.js');
    },
  },
  // 'leaf-validation-message': {
  //   register() {
  //     return import('@cigna/dcws_leaf-web-components/components/validation-message/validation-message.js');
  //   },
  // },
  'leaf-textarea': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/textarea/textarea.js');
    },
  },
  'leaf-tag': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tag/tag.js');
    },
  },
  'leaf-tab': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tabs/tabs.js');
    },
  },
  'leaf-tab-section': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tabs/tab-section.js');
    },
  },
  'leaf-tab-item': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tabs/tab-item.js');
    },
  },
  'leaf-spinner': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/spinner/spinner.js');
    },
  },
  'leaf-social-link': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/social-link/social-link.js');
    },
  },
  'leaf-select-option': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/select-option/select-option.js');
    },
  },
  'leaf-select': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/select/select.js');
    },
  },
  'leaf-radiogroup': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/radiogroup/radiogroup.js');
    },
  },
  'leaf-radio': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/radio/radio.js');
    },
  },
  'leaf-progress-bar': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/progress-bar/progress-bar.js');
    },
  },
  'leaf-multimedia-limelight': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/multimedia-limelight/multimedia-limelight.js');
    },
  },
  'leaf-modal': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/modal/modal.js');
    },
  },
  'leaf-list': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/list/list.js');
    },
  },
  'leaf-label': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/label/label.js');
    },
  },
  'leaf-input': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/input/input.js');
    },
  },
  'leaf-icon': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/icon/icon.js');
    },
  },
  'leaf-group': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/group/group.js');
    },
  },
  'leaf-flex-container': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/flex-container/flex-container.js');
    },
  },
  'leaf-divider': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/divider/divider.js');
    },
  },
  'leaf-datetime': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/datetime/datetime.js');
    },
  },
  'leaf-checkgroup': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/checkgroup/checkgroup.js');
    },
  },
  'leaf-checkbox': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/checkbox/checkbox.js');
    },
  },
  'leaf-card': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/card/card.js');
    },
  },
  'leaf-button-link': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/button-link/button-link.js');
    },
  },
  'leaf-button': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/button/button.js');
    },
  },
  'leaf-breadcrumbs-item': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/breadcrumbs-item/breadcrumbs-item.js');
    },
  },
  'leaf-breadcrumbs': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/breadcrumbs/breadcrumbs.js');
    },
  },
  'leaf-backdrop': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/backdrop/backdrop.js');
    },
  },
  'leaf-alert': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/alert/alert.js');
    },
  },
  'leaf-accordion-panel-inline': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/accordion-panel-inline/accordion-panel-inline.js');
    },
  },
  'leaf-accordion-panel': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/accordion-panel/accordion-panel.js');
    },
  },
  'leaf-accordion': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/accordion/accordion.js');
    },
  },
  'leaf-popover': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/popover/popover.js');
    },
  },
};

export const loadComponents = (logger?: Console) => {
  const loader = new Loader(components, logger);

  const stopLoader = () => {
    // <meta name="autoloader" content="stopafterload"> = stop autoloader after initial load
    const loaderMeta = document.querySelector('meta[name="autoloader"],meta[name="component-loader"]');
    if (loaderMeta?.getAttribute('content') === 'stopafterload') {
      loader.stop();
    }
  };

  let componentsDiscovered = false;
  document.addEventListener('DOMContentLoaded', () => {
    loader.discover(document.body, true).then(stopLoader);
    componentsDiscovered = true;
  });

  if (!componentsDiscovered) {
    loader.discover(document.body, true).then(stopLoader);
  }
};
