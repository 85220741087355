import { emit } from '@cigna/dcws_leaf-web-components/utilities/events.js';
import {
  getCleanPathname,
  addToSetIfTruthy,
  getParentElement,
  getElementHeader,
  getElementText,
  getElDownloadExitLink,
  sendAnalytics,
  getImpressionParentElement,
} from './utils.js';

export const setupClickTracking = (
  _logger?: Console,
  optionalExtraTrackers: Array<string> = [],
  customObj: Record<string, unknown> = {},
) => {
  const eventName = 'click';
  const useCapture = true;
  const elementsToTrack = [
    'A',
    'LEAF-BUTTON',
    'LEAF-BUTTON-LINK',
    'LEAF-TAB-ITEM',
    'LEAF-ACCORDION-PANEL',
    'LEAF-ACCORDION-PANEL-INLINE',
    'CHC-PHONE-NUMBER',
    ...optionalExtraTrackers,
  ];

  const callback = (event: Event) => {
    const products = '';
    let thisCustomObj: Record<string, unknown> = {};

    if ('composed' in event && typeof event.composedPath === 'function') {
      const path = event.composedPath();
      const targetElement = Array.from(path).find(
        (element) => element instanceof HTMLElement && elementsToTrack.includes(element.tagName),
      ) as HTMLElement;

      const isImpression = getImpressionParentElement(path, true) && getImpressionParentElement(path, true) !== 'body';
      const parentWithCustom = Array.from(path).find(
        (el) => el instanceof HTMLElement && (el as HTMLElement).hasAttribute('data-custom'),
      );

      (parentWithCustom as HTMLElement)
        ?.getAttribute('data-custom')
        ?.split('^')
        .forEach((prop) => {
          const propArr = prop.split(':');
          propArr[1] = propArr[1].replace(/\*/g, ':');
          propArr[1] = propArr[1].replace(/@%/g, '^');
          thisCustomObj[propArr[0]] = propArr[1];
        });

      if (parentWithCustom) thisCustomObj.eVar116 = window.digitalData.language;

      thisCustomObj = { ...customObj, ...thisCustomObj };
      if (!targetElement) return;
      emit(
        'analytics-click',
        {
          detail: {
            event,
            targetClicked: targetElement,
            targetHref: (targetElement as HTMLAnchorElement).href || '',
            targetTag: targetElement.tagName,
            targetParent: getParentElement(path),
          },
        },
        document.body,
      );
      const info = new Set([getCleanPathname(location.pathname)]);
      isImpression
        ? addToSetIfTruthy(info, getImpressionParentElement(path) as string)
        : addToSetIfTruthy(info, getParentElement(path) as string);
      addToSetIfTruthy(info, getElementHeader());
      addToSetIfTruthy(info, getElementText(targetElement));
      const digitalActions = {
        item: 'clicktracking',
        linkname: getElementText(targetElement),
        component: `${getParentElement(path)}${getElDownloadExitLink(targetElement as HTMLAnchorElement, true)}`,
        downloadexit: getElDownloadExitLink(targetElement as HTMLAnchorElement),
        value: [...info].join('|'),
      };
      sendAnalytics('actiontracking', products, digitalActions, thisCustomObj);
      thisCustomObj = {};
    }
  };

  document.addEventListener(eventName, callback, useCapture);
};
