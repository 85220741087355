import type { EnvironmentConfig } from './interfaces.js';

const digitalEdge = 'https://p-gsg.digitaledge.cigna.com';
const mycignaSpaURL = 'https://my.cigna.com';

export const environment: EnvironmentConfig = {
  i18n: {
    fallbackLng: 'en-us',
    load: 'currentOnly',
    lowerCaseLng: true,
    ns: [],
    supportedLngs: ['en-us', 'es-us'],
    backend: {
      loadPath: (lngs: string | string[], _namespaces: string | string[]) =>
        lngs === 'en-us'
          ? '/static/aep-pdp-control-sites-cigna-com/json/{{- ns}}.json'
          : '/static/aep-pdp-control-sites-cigna-com/{{lng}}/json/{{- ns}}.json',
    },
    detection: {
      order: ['htmlTag'],
    },
  },
  endpoints: {
    campaigns: `${digitalEdge}/webservices/common/v1/campaigns`,
    claimForm: `${digitalEdge}/webservices/aep-pdp-control-sites-cigna-com/v1/claims-form-submission`,
    relatedLinks: `${digitalEdge}/webservices/common/v1/related-links`,
    search: {
      endpoint: 'https://p-gsg.digitaledge.cigna.com/digital/cigna/search',
      indexes: ['index-public-cigna-prod', 'index-public-cignahealthwise-prod'],
    },
    medicarePlanFinder: `${digitalEdge}/igse/connecturedrx2`,
    igse: 'https://plans.cigna.com',
    zipSegment: '/static/aep-pdp-control-sites-cigna-com/json/ifp-eop-zip-segment.json',
    ifpArchive: '/static/aep-pdp-control-sites-cigna-com/json/ifp-archive.json',
    medicareConnectUsForm: 'https://apigateway0.cigna.com/v2/sforcethublead/lead/',
    pdlViewerDomain: ['https://static.cigna.com', 'prod', '/static/aep-pdp-control-sites-cigna-com/json/pdl-viewer-angular-files.json'],
    myCignaLogin: {
      mycignaSpaURL,
      redirectUri: 'https://www.cigna.com/web/public/guest/callback',
      clientId: 'tHVhC9z0tpbg5hhJxCDE',
      issuer: 'https://p.login.my.cigna.com/oauth2/default',
      guest: `${mycignaSpaURL}/web/public/guest`,
      consumer: `${mycignaSpaURL}/web/public/guest/federate`,
      forgotID: `${mycignaSpaURL}/web/public/consumer/forgotid`,
      forgotPassword: `${mycignaSpaURL}/web/public/consumer/forgotpassword`,
      userInfo: 'https://p-digital.digitaledge.cigna.com/v1/profile',
      cookieDomain: '.cigna.com',
    },
  },
  flags: {
    enableEloquaTestEmails: false,
  },
};
