/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Cookies from 'js-cookie';
import { formatNumber } from '@cigna/dcws_leaf-web-components/utilities/format-number.js';
import { ICampaignInfo } from '@cigna/cigna_digital_dcws_campaigns-phone-service';

export interface LinkListObj {
  [key: string]: {
    linkToFind: string[];
    linkParams: string;
    linkReplace: string;
  };
}

export const updateDynamicLinks = (linkURL: string, campaignObj: ICampaignInfo) => {
  const referralData = ['421903', '421904', 'OSYIFP', '421905', '421906'];
  const campaignMedium = campaignObj['campaignMedium-cipublic'];
  const campaignSource = campaignObj['campaignSource-cipublic'];
  const campaignPhone = campaignObj['campaignPhone-cipublic']!;
  const campaignPID = campaignObj['campaignPID-cipublic'];
  const campaignEID = campaignObj['campaignEID-cipublic'];
  const campaignCID = Cookies.get('campaign-cid-cipublic');
  const csbSuppCampaign = campaignObj['csbSuppCampaign-cipublic'];
  const ifpDenCampaign = campaignObj['ifpDenCampaign-cipublic'];
  const ifpMedCampaign = campaignObj['ifpMedCampaign-cipublic'];
  const campaignzipCode = Cookies.get('campaignZipCode-cipublic');
  const campaignSID = Cookies.get('campaign-sid-cipublic');
  const campaignDIV = Cookies.get('campaign-div-cipublic');
  const campaignAFID = Cookies.get('campaign-afid-cipublic');
  const addPID = campaignPID && !referralData.includes(campaignPID) ? `&PID=${campaignPID}` : '';
  const addEID = campaignEID && !referralData.includes(campaignEID) ? `&EID=${campaignEID}` : '';
  const addZip = campaignzipCode && campaignzipCode.length ? `&zip_code=${campaignzipCode}` : '';
  const addDIV = campaignDIV && !referralData.includes(campaignDIV) ? `&div=${campaignDIV}` : '';
  const addAFID = campaignAFID && !referralData.includes(campaignAFID) ? `&afid=${campaignAFID}` : '';
  const addSID = campaignSID && !referralData.includes(campaignSID) ? `&sid=${campaignSID}` : '';
  const presetCID = window.location.pathname.includes('/individuals-families/shop-plans/supplemental/')
    ? 's037'
    : 's030';

  let linkChangeResult = '';
  const linkList: LinkListObj = {
    quoteSupplemental: {
      linkToFind: ['cignasupplemental.expressenroll.com'],
      linkParams: `cid=${campaignCID ? campaignCID : presetCID}&campaign_id=${csbSuppCampaign}`,
      linkReplace: 'https://cignasupplemental.expressenroll.com/',
    },
    quoteDental: {
      linkToFind: ['cignadental.com/equotes/startInterview.action'],
      linkParams: `link_case=quote&campaign_ID=IFP_SAD&utm_campaign=${ifpDenCampaign}${addPID}`,
      linkReplace: 'https://cignadental.com/equotes/startInterview.action',
    },
    quoteMedical: {
      linkToFind: ['cignaindividual.com/public-ede'],
      linkParams: `utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${ifpMedCampaign}&campaign_ID=${ifpMedCampaign}&call=${formatNumber(
        campaignPhone,
        '.',
      )}&_agent_id=cigna${addPID}${addEID}${addZip}`,
      linkReplace: 'https://cignaindividual.com/public-ede/',
    },
    quoteMedHealthSherpa: {
      linkToFind: ['cigna.healthsherpa.com'],
      linkParams: `utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${ifpMedCampaign}&campaign_ID=${ifpMedCampaign}&call=${formatNumber(
        campaignPhone,
        '.',
      )}&_agent_id=cigna${addPID}${addEID}${addZip}`,
      linkReplace: 'https://cigna.healthsherpa.com/',
    },
    quoteMedIFP: {
      linkToFind: ['shopIFP.cigna.com', 'shopifp.cigna.com'],
      linkParams: `utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${ifpMedCampaign}&campaign_ID=${ifpMedCampaign}&call=${formatNumber(
        campaignPhone,
        '.',
      )}&_agent_id=cigna${addPID}${addEID}${addZip}`,
      linkReplace: 'https://shopifp.cigna.com/',
    },
    quoteMedShopCigna: {
      linkToFind: ['shopcigna.com'],
      linkParams: `utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${ifpMedCampaign}&campaign_ID=${ifpMedCampaign}&call=${formatNumber(
        campaignPhone,
        '.',
      )}&_agent_id=cigna${addPID}${addEID}${addZip}`,
      linkReplace: 'https://shopcigna.com/',
    },
    quoteMedicare: {
      linkToFind: ['cignasupplemental.com/equotes/startInterview.action'],
      linkParams: `cid=${campaignCID ? campaignCID : presetCID}&campaign_ID=${csbSuppCampaign}&call=${formatNumber(
        campaignPhone,
        '.',
      )}&utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${csbSuppCampaign}${addSID}${addAFID}${addDIV}${addPID}${addZip}`,
      linkReplace: 'https://cignasupplemental.com/equotes/startInterview.action',
    },
  };

  Object.keys(linkList).forEach((key: string) => {
    if (linkList[key].linkToFind.some((link) => linkURL.includes(link))) {
      linkChangeResult = `${linkList[key].linkReplace}?${linkList[key].linkParams}`;
    }
  });
  return linkChangeResult;
};
